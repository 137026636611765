import React from 'react';
import { useAuthContext } from 'src/auth/hooks';
import CreateMultiFactorAuthentication from './components/CreateMultiFactorAuthentication';
import { Card, Grid } from '@mui/material';

function AccountMFA() {
  const { user } = useAuthContext();
  const currentUser = user.auth.currentUser;
  
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item>
        <Card sx={{ p: 3 }}>
          <CreateMultiFactorAuthentication currentUser={currentUser} />
        </Card>
      </Grid>
    </Grid>
  );
}

export default AccountMFA;
