import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'src/auth/hooks';
import { paths } from 'src/routes/paths';
import { reauthenticate } from 'src/sections/auth/firebase/authentications';

function PhoneRegistration({getPhoneNumber}) {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [openReauthDialog, setOpenReauthDialog] = useState(false);
  const [reauthPassword, setReauthPassword] = useState('');
  const {enqueueSnackbar} = useSnackbar();
  const {user} = useAuthContext();
  const currentUser = user.auth.currentUser

  const handleReauthDialogOpen = () => setOpenReauthDialog(true);
  const handleReauthenticate = async () => {
    try {
      await reauthenticate(currentUser, reauthPassword);
      if (phoneNumber) {
        getPhoneNumber(phoneNumber)
      }
      handleReauthDialogClose();
      // Redirigir a la página de configuración de MFA
      navigate(paths.dashboard.user.mfa);
    } catch (error) {
      console.error('Error al reautenticar:', error);
      if (error.code === 'auth/invalid-credential') {
        enqueueSnackbar('Contraseña incorrecta. Por favor, intente nuevamente.', { variant: 'error' });
      } else {
        enqueueSnackbar('Error al reautenticar. Por favor, intente nuevamente.', { variant: 'error' });
      }
    }
  };
  const handleReauthDialogClose = () => {
    setOpenReauthDialog(false);
    setReauthPassword('');
  };

  const isValidPhoneNumber = (number) => {
    return /^\+569[0-9]{8}$/.test(number);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (isValidPhoneNumber(value)) {
      setPhoneNumber(value);
    } else {
      setPhoneNumber('');
    }
  };

  return (
    <>
      <TextField
        name="phoneNumber"
        label="Número de teléfono"
        onChange={handlePhoneNumberChange}
        helperText="Ingrese su número de teléfono con código de país (ej. +56912345678)"
      />
      <Stack direction="row" spacing={3} marginTop={3} justifyContent="space-around">
        <Button fullWidth onClick={() => navigate(paths.dashboard.user.account)} variant="contained" color="error">
          Cancelar
        </Button>

        <LoadingButton
          fullWidth
          color="primary"
          size="large"
          type="button"
          variant="contained"
          onClick={handleReauthDialogOpen}
          disabled={!isValidPhoneNumber(phoneNumber)}
        >
          Enviar SMS
        </LoadingButton>
      </Stack>

      <Dialog maxWidth='xl' open={openReauthDialog} onClose={handleReauthDialogClose}>
        <DialogTitle>Confirme su contraseña</DialogTitle>
        <DialogContent>
          <TextField
            name="reauthPassword"
            type='password'
            label="Contraseña actual"
            value={reauthPassword}
            onChange={(e) => setReauthPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button onClick={handleReauthDialogClose} variant='contained' color='error'>Cancelar</Button>
          <Button onClick={handleReauthenticate} variant="contained" color='primary'>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PhoneRegistration;
