import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Code from './Code';
import { unenrollMFA } from 'src/sections/auth/firebase/authentications';
import { useSnackbar } from 'notistack';
import { paths } from 'src/routes/paths';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { getAuth, getMultiFactorResolver, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth';
import useRecaptcha from 'src/auth/hooks/use-recaptcha';

function CodeDelete2FA({ currentUser }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState('');
  const [mfaInfo, setMfaInfo] = useState(null);
  const [verificationId, setVerificationId] = useState('');
  const [openMfaDialog, setOpenMfaDialog] = useState(true);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const createRecaptcha = useRecaptcha('delete-mfa')
  const auth = getAuth();

  useEffect(() => {
    const verifier = createRecaptcha();
    setRecaptchaVerifier(verifier)
  }, [createRecaptcha])
  
  const handleDeactivate2FA = async () => {
    try {
      if (!password) {
        enqueueSnackbar('Por favor, ingrese su contraseña actual', { variant: 'error' });
        return;
      }
      await unenrollMFA(currentUser, password);
      enqueueSnackbar('Ha desactivado la verificación de 2 pasos.', { variant: 'success' });
      setOpenMfaDialog(false);
    } catch (error) {
      console.error('Error al desactivar 2FA:', error);
      if (error.code === 'auth/invalid-credential'){
        enqueueSnackbar('Contraseña incorrecta', { variant: 'error' });
      }
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, error);
        const hints = resolver.hints;
        setMfaInfo({ resolver, hints });
        const selectedHint = hints[0]; // Asumimos que solo hay un factor MFA
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          { multiFactorHint: selectedHint, session: resolver.session },
          recaptchaVerifier
        );
        setVerificationId(verificationId)

        } else {
        console.log(error)
        }
      }
    };

  const handleVerifyMfa = async (verificationCode) => {
    try {
      if (!mfaInfo) {
        console.error('Info MFA no disponible');
        return;
      }
      const { resolver, hints } = mfaInfo;
      const selectedHint = hints[0];
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);
      await resolver.resolveSignIn(multiFactorAssertion);

      // Una vez resuelto el MFA, intentamos desenrollar nuevamente
      await multiFactor(currentUser).unenroll(selectedHint);

      enqueueSnackbar('Ha desactivado la verificación de 2 pasos.', { variant: 'success' });
      navigate(paths.dashboard.user.account);
    } catch (error) {
      console.error('Error en la verificación MFA:', error);
      enqueueSnackbar('Error en la verificación MFA', { variant: 'error' });
    }
  };

  return (
    <>
      {mfaInfo ? (
        <Code getCode={handleVerifyMfa} />
      ) : (
        <Dialog open={openMfaDialog}>
          <DialogTitle>Confirme su contraseña</DialogTitle>
          <DialogContent>
            <TextField
              name="reauthPassword"
              type="password"
              label="Contraseña actual"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions sx={{justifyContent: 'space-between'}}>
            <Button onClick={() => navigate(paths.dashboard.user.account)} variant='contained' color='error'>Cancelar</Button>
            <Button onClick={handleDeactivate2FA} variant="contained" color='primary'>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div id='delete-mfa'></div>
    </>
  );
}

export default CodeDelete2FA;