import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
// config
import { FIREBASE_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';
// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'UPDATE_USER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userProfile = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userProfile);

          const profile = docSnap.data();

          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...user,
                ...profile,
                id: user.uid,
                role: 'admin',
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const updateUser = useCallback(
    (user) => {
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const verifyUserMFA = useCallback(async (error, recaptchaVerifier, selectedIndex) => {
    const resolver = getMultiFactorResolver(AUTH, error);
    
    if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOption = {
        multiFactorHint: resolver.hints[selectedIndex],
        session: resolver.session
      };
      
      const phoneAuthProvider = new PhoneAuthProvider(AUTH);
      try {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOption, recaptchaVerifier);
        return { verificationId, resolver };
      } catch (e) {
        console.error('Error en verifyPhoneNumber:', e);
        return false;
      }
    }
    return false;
  }, []);

  const handleMFA = useCallback(async (error, recaptchaVerifier) => {
    if (error.code === 'auth/multi-factor-auth-required' && recaptchaVerifier) {
      const data = await verifyUserMFA(error, recaptchaVerifier, 0);
  
      if (!data) {
        console.error('No hay data de MFA');
        throw new Error('Fallo en la verificación MFA');
      } else {
        return data;
      }
    } else {
      console.error('Error no relacionado con MFA o falta recaptchaVerifier');
      throw error;
    }
  }, [verifyUserMFA]);


  const login = useCallback(async (email, password, recaptchaVerifier) => {
    try {
      const userCredential = await signInWithEmailAndPassword(AUTH, email, password);
      return userCredential.user;
    } catch (error) {
      console.error('Error de login:', error);
      if (error.code === 'auth/multi-factor-auth-required') {
        return handleMFA(error, recaptchaVerifier);
      }
      throw error;
    }
  }, [handleMFA]);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const register = useCallback(async (email, password, firstName, lastName) => {
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

    await sendEmailVerification(newUser.user);

    const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
    });
  }, []);

  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: state.loading,
      authenticated: !!state.user,
      unauthenticated: !state.user,
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      updateUser,
      verifyUserMFA,
    }),
    [
      state.user,
      state.loading,
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      updateUser,
      verifyUserMFA,
    ]
  );
  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
