import { EmailAuthProvider, getAuth, getMultiFactorResolver, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, reauthenticateWithCredential, sendEmailVerification } from "firebase/auth";
const auth = getAuth();

export async function reauthenticate(currentUser, password) {
  if (!password) {
    throw new Error('La contraseña es requerida para la reautenticación');
  }

  try {
    const credential = EmailAuthProvider.credential(currentUser.email, password);
    await reauthenticateWithCredential(currentUser, credential);
    return true;
  } catch (error) {
    if (error.code === 'auth/multi-factor-auth-required') {
    } else if (error.code === 'auth/wrong-password') {
      console.error('Contraseña incorrecta');
      throw new Error('La contraseña proporcionada es incorrecta');
    } else {
      console.error('Error al reautenticar:', error);
    }
    throw error;
  }
}

export async function unenrollMFA(user, password) {
  try {
    // Primero, reautenticar al usuario
    await reauthenticate(user, password);

    // Obtener los factores MFA enrollados
    const multiFactorUser = multiFactor(user);
    const enrolledFactors = multiFactorUser.enrolledFactors;

    if (enrolledFactors.length === 0) {
      throw new Error('No hay factores MFA enrollados para desactivar');
    }

    // Desenrollar todos los factores MFA
    await Promise.all(enrolledFactors.map(factor => 
      multiFactorUser.unenroll(factor)
    ));

    return true; // Desactivación exitosa
  } catch (error) {
    console.error('Error al desactivar MFA:', error);
    throw error;
  }
}

// Función para verificar y enviar correo de verificación si es necesario
export async function verifyEmail(user, enqueueSnackbar) {
  if (!user.emailVerified) {
    try {
      await sendEmailVerification(user);
      enqueueSnackbar("Correo de verificación enviado. Por favor, verifique su bandeja de entrada.",{variant: 'success'});
      return false;
    } catch (error) {
      enqueueSnackbar("Error al enviar correo de verificación, intente nuevamente.",{variant: 'error'});
      console.error("Error al enviar correo de verificación:", error);
      return false;
    }
  }
  return true;
}

export function verifyIfUserIsEnrolled(user) {
  const enrolledFactors = multiFactor(user).enrolledFactors;
  return enrolledFactors.length > 0;
}
// Función para recargar el usuario y obtener el estado más reciente
export async function reloadUser() {
  try {
    await auth.currentUser.reload();
    return auth.currentUser;
  } catch (error) {
    console.error("Error al recargar usuario:", error);
    return null;
  }
}

export async function verifyPhoneNumber(user, phoneNumber, recaptchaVerifier) {
  // Primero, verificamos si el correo está verificado
  reloadUser();
  const session = await multiFactor(user).getSession();
  const phoneInfoOption = {
    phoneNumber,
    session
  };

  const phoneAuthProvider = new PhoneAuthProvider(auth);
  try {
    return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOption, recaptchaVerifier);
  } catch (e) {
    console.log(e);
    return false;
  }
}


export async function verifyUserMFA(error, recaptchaVerifier, selectedIndex) {
  const resolver = getMultiFactorResolver(auth, error)
  
  if(resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const phoneInfoOption = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session
    }
    
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    try {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOption, recaptchaVerifier);
      return { verificationId, resolver }
    } catch (e) {
      console.log(e)
      return false
    }
  }
}

export async function enrollUser(user, verificationCodeId, verificationCode, enqueueSnackbar) {
  // Verificamos nuevamente el estado del correo antes de enrollar
  if (!(await verifyEmail(user))) {
    return false; // No continuamos si el correo no está verificado
  }

  const phoneAuthCredential = PhoneAuthProvider.credential(verificationCodeId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);

  try {
    await multiFactor(user).enroll(multiFactorAssertion, 'Telefono Personal');
    enqueueSnackbar('Autenticación en 2 pasos configurada correctamente.',{variant: 'success'})
    return true;
  } catch (error) {
    enqueueSnackbar('El código ingresado no es correcto.',{variant: 'error'})
    console.error("Error al enrollar usuario en MFA:", error);
    return false;
  }
}

export async function verifyUserEnrolled(verificationMFA, verificationCode, enqueueSnackbar) {
  const {verificationId, resolver} = verificationMFA;
  const credentials = PhoneAuthProvider.credential(verificationId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

  try{
    await resolver.resolveSignIn(multiFactorAssertion);
    return true;
  }catch (error){
    if (error.code = 'auth/invalid-verification-code') {
      enqueueSnackbar('El código ingresado es incorrecto.', {variant: 'error'})
    } else{
      console.error(error)
    }
    return false;
  }
}
