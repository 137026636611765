import { useEffect, useRef, useCallback } from 'react';
import { RecaptchaVerifier } from 'firebase/auth';
import { getAuth } from 'firebase/auth';

export default function useRecaptcha(containerId) {
  const recaptchaVerifier = useRef(null);
  const auth = getAuth();

  const createRecaptcha = useCallback(() => {
    if (recaptchaVerifier.current) {
      recaptchaVerifier.current.clear();
    }
    recaptchaVerifier.current = new RecaptchaVerifier(auth, containerId, {
      size: 'invisible',
    });
    return recaptchaVerifier.current;
  }, [auth, containerId]);

  useEffect(() => {
    createRecaptcha();
    return () => {
      if (recaptchaVerifier.current) {
        recaptchaVerifier.current.clear();
      }
    };
  }, [createRecaptcha]);

  return createRecaptcha;
}