import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@mui/material';
import { getAuth } from 'firebase/auth';
import CodeDelete2FA from './components/CodeDelete2FA';
import useRecaptcha from 'src/auth/hooks/use-recaptcha';

function AccountMFADeactivate() {
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const createRecaptcha = useRecaptcha('delete-mfa');

  useEffect(() => {
    const verifier = createRecaptcha();
    setRecaptchaVerifier(verifier);

  }, [createRecaptcha]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Card sx={{ p: 3 }}>
          {recaptchaVerifier && (
            <CodeDelete2FA
              currentUser={currentUser}
              recaptchaVerifier={recaptchaVerifier}
            />
          )}
          <div id="delete-mfa"></div>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AccountMFADeactivate;