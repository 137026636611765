import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('Análisis'),
        items: [
          { title: t('Dashboard'), path: paths.dashboard.root, icon: ICONS.dashboard },
          {
            title: t('Estadística'),
            path: paths.dashboard.seguros.estadisticas,
            icon: ICONS.analytics,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('gestión'),
        items: [
          // USER
          {
            title: t('Tareas'),
            path: paths.dashboard.seguros.tareas,
            icon: ICONS.user,
          },
          // CALENDAR
          {
            title: t('Agenda'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
          },
        ],
      },

      // SEGUROS
      // ----------------------------------------------------------------------
      {
        subheader: 'Seguros',
        items: [
          { title: 'Pólizas', path: paths.dashboard.seguros.polizas, icon: ICONS.lock },
          { title: 'Siniestros', path: paths.dashboard.seguros.siniestros, icon: ICONS.invoice },
          {
            title: 'Riesgos',
            path: paths.dashboard.seguros.dashboardRiesgos,
            icon: ICONS.dashboard,
          },
          {
            title: 'Herramientas',
            path: paths.dashboard.seguros.herramientas,
            icon: ICONS.job,
          },
        ],
      },

      // SEGUROS
      // ----------------------------------------------------------------------
      {
        subheader: 'Soporte',
        items: [{ title: 'Soporte', path: paths.dashboard.soporte.soporte, icon: ICONS.mail }],
      },
    ],
    [t]
  );

  return data;
}
