import { useContext } from 'react';
import { AuthContext } from '../auth/context/firebase/auth-context';
import {
  collection,
  doc,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  writeBatch,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import { parseISO, isToday, addDays, isBefore, subDays } from 'date-fns';

const useTareasBD = () => {
  const { user } = useContext(AuthContext);
  const tareasRef = collection(db, `${user.empresa}/tareas/datos`);

  const listenTareas = (callback) => {
    const unsubscribe = onSnapshot(tareasRef, (querySnapshot) => {
      const tareas = [];
      const currentDate = new Date();
      const fiveDaysFromNow = addDays(currentDate, 5);
      const todayMinusOneDay = subDays(currentDate, 1);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const fechaVencimiento = parseISO(data.fechaVencimiento);
        let estatus;
        if (data.completada) {
          estatus = 'completada';
        } else if (isBefore(fechaVencimiento, todayMinusOneDay)) {
          estatus = 'vencida';
        } else if (isToday(fechaVencimiento)) {
          estatus = 'próximas a vencer';
        } else if (isBefore(fechaVencimiento, fiveDaysFromNow)) {
          estatus = 'próximas a vencer';
        } else {
          estatus = 'pendiente';
        }

        tareas.push({
          id: doc.id,
          completada: data.completada,
          nombre: data.nombre,
          fechaVencimiento: data.fechaVencimiento,
          prioridad: data.prioridad,
          detalle: data.detalle,
          createdAt: data.createdAt,
          estatus: estatus,
          codigo: data.codigo,
          lectura: data.lectura || false,
          usuarioId: data.usuarioId,
        });
      });

      tareas.sort((a, b) => {
        const dateA = parseISO(a.fechaVencimiento);
        const dateB = parseISO(b.fechaVencimiento);
        return isBefore(dateA, dateB) ? -1 : 1;
      });
      callback(tareas);
    });

    return () => unsubscribe();
  };

  const getTareaSiniestro = async (user, id, codigoTarea) => {
    try {
      const q = query(
        tareasRef,
        where('idSiniestro', '==', id),
        where('codigo', '==', codigoTarea)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs[0]?.data();
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const addTarea = async (tarea) => {
    const createdAt = new Date().toISOString();
    const fechaVencimiento = new Date(tarea.fechaVencimiento).toISOString();

    try {
      const docRef = await addDoc(tareasRef, {
        ...tarea,
        createdAt,
        fechaVencimiento,
        usuarioId: user.id,
      });
      const tareaConId = {
        id: docRef.id,
        ...tarea,
        createdAt,
        fechaVencimiento,
        usuarioId: user.id,
      };
      await updateDoc(doc(tareasRef, docRef.id), tareaConId);
      return docRef.id;
    } catch (error) {
      console.error('Error al agregar tarea: ', error);
      throw error;
    }
  };

  const updateTarea = (id, tarea) => {
    const tareaRef = doc(tareasRef, id);
    const fechaVencimiento = new Date(tarea.fechaVencimiento).toISOString();
    return updateDoc(tareaRef, { ...tarea, fechaVencimiento });
  };

  const deleteTarea = (id) => {
    const tareaRef = doc(tareasRef, id);
    return deleteDoc(tareaRef);
  };

  const deleteTareasPoliza = async (id) => {
    const q = query(tareasRef, where('idPoliza', '==', id));
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
  };

  return {
    listenTareas,
    getTareaSiniestro,
    addTarea,
    updateTarea,
    deleteTarea,
    deleteTareasPoliza,
  };
};

export default useTareasBD;
