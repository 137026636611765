import React from 'react'
import Code from './Code'
import { enrollUser } from 'src/sections/auth/firebase/authentications'
import { useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';
import { useSnackbar } from 'src/components/snackbar';

function CodeSignUp({currentUser, verificationCodeId}) {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  async function getCode(code){
    const response = await enrollUser(currentUser, verificationCodeId, code, enqueueSnackbar);
    if (response){
      navigate(paths.dashboard.root);
    } else {
      console.log('Algo salió mal.')
    }
  }

  return <Code getCode={getCode}/>
}

export default CodeSignUp