import React, { useState } from 'react';
import PhoneRegistration from './PhoneRegistration';
import useRecaptcha from 'src/auth/hooks/use-recaptcha';
import { verifyPhoneNumber } from 'src/sections/auth/firebase/authentications';
import CodeSignUp from './CodeSignUp';

function CreateMultiFactorAuthentication({ currentUser }) {
  const createRecaptcha = useRecaptcha('create-mfa');
  const [verificationCodeId, setVerificationCodeId] = useState(null);

  async function getPhoneNumber(phoneNumber) {
    if (!currentUser) {
      return;
    }
    const recaptcha = createRecaptcha();
    const verificationId = await verifyPhoneNumber(currentUser, phoneNumber, recaptcha);

    if (!verificationId) {
      console.log('No verif ID');
    } else {
      setVerificationCodeId(verificationId);
    }
  }

  return (
    <>
      {!verificationCodeId && <PhoneRegistration getPhoneNumber={getPhoneNumber} />}
      {verificationCodeId && (
        <CodeSignUp currentUser={currentUser} verificationCodeId={verificationCodeId} />
      )}
      <div id="create-mfa"></div>

      
    </>
  );
}

export default CreateMultiFactorAuthentication;
