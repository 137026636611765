import React, { useState, useRef, useEffect } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import { paths } from 'src/routes/paths';

const Code = ({getCode}) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 6);
  }, []);

  const handleChange = (index) => (event) => {
    const value = event.target.value;
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index) => (event) => {
    if (event.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleClick = () => {
    const finalCode = code.join('');
    getCode(finalCode);
  };

  return (
    <>
      <Typography variant='h6'>Ingresa el código en el SMS enviado a tu celular:</Typography>
      <div className="flex gap-x-4 mt-6 md:mt-8 pb-4">
        {code.map((value, index) => (
          <TextField
            key={index}
            value={value}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' }
            }}
            inputRef={(el) => (inputRefs.current[index] = el)}
            variant="outlined"
            size="small"
          />
        ))}
      </div>

      <Stack direction="row" spacing={3} marginTop={3} justifyContent="space-around">
        <Button fullWidth onClick={() => navigate(paths.dashboard.user.account)} variant="contained" color="error">
          Cancelar
        </Button>

        <LoadingButton
          fullWidth 
          color="primary"
          size="large"
          type="button"
          variant="contained"
          onClick={handleClick}
          loading={false}
        >
          Confirmar
        </LoadingButton>
      </Stack>
    </>
  );
};

export default Code;