import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fDate } from 'src/utils/format-time';
import { useSnackbar } from 'src/components/snackbar';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTareasBD from 'src/baseDatos/tareasDB';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TaskDialog = ({ open, handleClose, initialTask }) => {
  const { addTarea, updateTarea } = useTareasBD();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('El nombre de la tarea es obligatorio'),
    fechaVencimiento: Yup.date().nullable().required('La fecha de vencimiento es obligatoria'),
    prioridad: Yup.string().required('La prioridad es obligatoria'),
    detalle: Yup.string().required('El detalle de la tarea es obligatorio'),
    completada: Yup.boolean().required('El estatus es obligatorio'),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (initialTask) {
      reset({
        nombre: initialTask.nombre || '',
        fechaVencimiento: initialTask.fechaVencimiento
          ? new Date(initialTask.fechaVencimiento)
          : null,
        prioridad: initialTask.prioridad || 'baja',
        detalle: initialTask.detalle || '',
        completada: initialTask.completada || false,
        codigo: initialTask.codigo || 'gestion',
      });
    } else {
      reset({
        nombre: '',
        fechaVencimiento: null,
        prioridad: 'baja',
        detalle: '',
        completada: false,
        codigo: 'gestion',
      });
    }
  }, [initialTask, reset]);

  const onSubmit = async (data) => {
    const tarea = {
      ...data,
      fechaVencimiento: fDate(data.fechaVencimiento),
      codigo: 'gestion',
    };

    try {
      if (initialTask?.id) {
        await updateTarea(initialTask.id, tarea);
        enqueueSnackbar('Tarea editada correctamente', { variant: 'success' });
      } else {
        await addTarea(tarea);
        enqueueSnackbar('Tarea agregada correctamente', { variant: 'success' });
      }
      handleClose();
      reset({
        nombre: '',
        fechaVencimiento: null,
        prioridad: 'baja',
        detalle: '',
        completada: false,
        codigo: 'gestion',
      });
    } catch (error) {
      enqueueSnackbar('Error al guardar la tarea', { variant: 'error' });
      console.error('Error al guardar la tarea:', error);
    }
  };

  const handleCancelar = () => {
    reset({
      nombre: '',
      fechaVencimiento: null,
      prioridad: 'baja',
      detalle: '',
      completada: false,
      codigo: 'gestion',
    });
    handleClose();
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleCancelar}>
      <DialogTitle>{initialTask ? 'Editar Tarea' : 'Agregar Tarea'}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="nombre"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nombre de la tarea"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2, marginTop: 0.5 }}
                error={!!errors.nombre}
                helperText={errors.nombre?.message}
              />
            )}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="fechaVencimiento"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  format="dd/MM/yyyy"
                  sx={{ marginBottom: 2 }}
                  label="Fecha de vencimiento"
                  onChange={(date) => field.onChange(date)}
                  clearable
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!errors.fechaVencimiento,
                      helperText: errors.fechaVencimiento?.message,
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>

          <Controller
            name="completada"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
                error={!!errors.completada}
              >
                <InputLabel>Estatus</InputLabel>
                <Select {...field} label="Estatus">
                  <MenuItem value={false}>Pendiente</MenuItem>
                  <MenuItem value={true}>Completada</MenuItem>
                </Select>
                {errors.completada && <p>{errors.completada.message}</p>}
              </FormControl>
            )}
          />
          <Controller
            name="prioridad"
            control={control}
            render={({ field }) => (
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
                error={!!errors.prioridad}
              >
                <InputLabel>Prioridad</InputLabel>
                <Select {...field} label="Prioridad">
                  <MenuItem value="alta">Alta</MenuItem>
                  <MenuItem value="media">Media</MenuItem>
                  <MenuItem value="baja">Baja</MenuItem>
                </Select>
                {errors.prioridad && <p>{errors.prioridad.message}</p>}
              </FormControl>
            )}
          />
          <Controller
            name="detalle"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Detalle de la tarea"
                variant="outlined"
                multiline
                fullWidth
                error={!!errors.detalle}
                helperText={errors.detalle?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelar} color="primary">
            Cancelar
          </Button>
          <Button type="submit" color="primary">
            {initialTask ? 'Guardar' : 'Agregar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TaskDialog;
