import React, { useState, useCallback, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import { m } from 'framer-motion';
import { useBoolean } from 'src/hooks/use-boolean';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import NotificationItem from './notification-item';
import TaskDialog from 'src/sections/seguros/tareas/TaskDialog';
import useTareasBD from 'src/baseDatos/tareasDB';

const ITEMS_PER_PAGE = 5;

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const [currentTab, setCurrentTab] = useState('vencidas');
  const [currentPageVencidas, setCurrentPageVencidas] = useState(1);
  const [currentPageProximas, setCurrentPageProximas] = useState(1);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);

  const tareasBD = useTareasBD();
  const [notifications, setNotifications] = useState([]);
  const [vencidas, setVencidas] = useState([]);
  const [proximas, setProximas] = useState([]);

  useEffect(() => {
    const unsubscribe = tareasBD.listenTareas((tareas) => {
      setNotifications(tareas);
      const vencidasTareas = tareas.filter((tarea) => tarea.estatus === 'vencida');
      const proximasTareas = tareas.filter((tarea) => tarea.estatus === 'próximas a vencer');
      setVencidas(vencidasTareas);
      setProximas(proximasTareas);
    });
    return () => unsubscribe();
  }, []);

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handlePageChangeVencidas = (event, page) => {
    setCurrentPageVencidas(page);
  };

  const handlePageChangeProximas = (event, page) => {
    setCurrentPageProximas(page);
  };

  const handleTaskClick = (task) => {
    const formattedTask = {
      ...task,
      fechaVencimiento: task.fechaVencimiento.split('T')[0],
    };
    setSelectedTask(formattedTask);
    setOpenTaskDialog(true);
  };

  const handleCheckClick = async (task) => {
    const updatedTask = { ...task, lectura: !task.lectura };
    await tareasBD.updateTarea(task.id, updatedTask);
  };

  const vencidasCount = vencidas.length;
  const proximasCount = proximas.length;
  const unreadCount = notifications.filter(
    (tarea) =>
      (tarea.estatus === 'vencida' || tarea.estatus === 'próximas a vencer') && !tarea.lectura
  ).length;

  const getPaginatedData = (data, currentPage) => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return data.slice(startIndex, endIndex);
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notificaciones
      </Typography>
      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const paginatedVencidas = getPaginatedData(vencidas, currentPageVencidas);
  const paginatedProximas = getPaginatedData(proximas, currentPageProximas);

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      <Tab
        key="vencidas"
        iconPosition="end"
        value="vencidas"
        label="Vencidas"
        icon={
          <Label
            variant={currentTab === 'vencidas' ? 'filled' : 'soft'}
            color={currentTab === 'vencidas' ? 'info' : 'default'}
          >
            {vencidasCount}
          </Label>
        }
        sx={{
          '&:not(:last-of-type)': {
            mr: 3,
          },
        }}
      />
      <Tab
        key="proximas"
        iconPosition="end"
        value="proximas"
        label="Próximas a vencer"
        icon={
          <Label
            variant={currentTab === 'proximas' ? 'filled' : 'soft'}
            color={currentTab === 'proximas' ? 'success' : 'default'}
          >
            {proximasCount}
          </Label>
        }
        sx={{
          '&:not(:last-of-type)': {
            mr: 3,
          },
        }}
      />
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
        <List disablePadding>
          {currentTab === 'vencidas'
            ? paginatedVencidas.map((tarea, index) => (
                <React.Fragment key={tarea.id}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleTaskClick(tarea)}
                    sx={{
                      bgcolor: tarea.lectura ? 'background.paper' : 'background.default',
                      px: 2,
                      py: 1,
                      borderRadius: 1,
                      cursor: 'pointer',
                    }}
                  >
                    <NotificationItem notification={tarea} />
                    <Tooltip title={tarea.lectura ? 'Marcar como no leído' : 'Marcar como leído'}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCheckClick(tarea);
                        }}
                      >
                        <Iconify
                          icon={
                            tarea.lectura
                              ? 'mdi:checkbox-marked-circle-outline'
                              : 'mdi:checkbox-blank-circle-outline'
                          }
                          color={tarea.lectura ? 'green' : 'gray'}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  {index !== paginatedVencidas.length - 1 && <Divider />}
                </React.Fragment>
              ))
            : paginatedProximas.map((tarea, index) => (
                <React.Fragment key={tarea.id}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleTaskClick(tarea)}
                    sx={{
                      bgcolor: tarea.lectura ? 'background.paper' : 'background.default',
                      px: 2,
                      py: 1,
                      borderRadius: 1,
                      cursor: 'pointer', // Cambia el puntero al pasar sobre una tarea
                    }}
                  >
                    <NotificationItem notification={tarea} />
                    <Tooltip title={tarea.lectura ? 'Marcar como no leído' : 'Marcar como leído'}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCheckClick(tarea);
                        }}
                      >
                        <Iconify
                          icon={
                            tarea.lectura
                              ? 'mdi:checkbox-marked-circle-outline'
                              : 'mdi:checkbox-blank-circle-outline'
                          }
                          color={tarea.lectura ? 'green' : 'gray'}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  {index !== paginatedProximas.length - 1 && <Divider />}
                </React.Fragment>
              ))}
        </List>
        {currentTab === 'vencidas' ? (
          <Pagination
            count={Math.ceil(vencidas.length / ITEMS_PER_PAGE)}
            page={currentPageVencidas}
            onChange={handlePageChangeVencidas}
          />
        ) : (
          <Pagination
            count={Math.ceil(proximas.length / ITEMS_PER_PAGE)}
            page={currentPageProximas}
            onChange={handlePageChangeProximas}
          />
        )}
      </Box>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={unreadCount} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>
        <Divider />
        {renderList}
      </Drawer>

      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          handleClose={() => setOpenTaskDialog(false)}
          initialTask={selectedTask}
        />
      )}
    </>
  );
}
